import React from "react"
import Whatsapp from "../../../static/assets/whatsapp.svg"

const WhatsappIcon = () => (
  <a
    className="whats-app"
    href="tel:905465883442"
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className="blob green">
      <img
        className="fa fa-whatsapp my-float"
        title="Whatsapp İletişim Hattı"
        src={Whatsapp}
        alt="Bilişim Avukatı, Sosyal Medya Avukatı, Fenomen Avukatı, İnternet Avukatı, Teknoloji Avukatı"
      />
    </div>
  </a>
)

export default WhatsappIcon
