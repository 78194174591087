import React from "react"
import { Link } from "gatsby"
import Logo from "../../images/logo.svg"
import Left from "../../images/footer-left.svg"
import Right from "../../images/footer-right.svg"

const d = new Date()
const y = d.getFullYear()

const Footer = () => {
  return (
    <div>
      <div className="section sub-footer-blocks">
        <div className="wrap-wide">
          <div className="left">
            <Left />
          </div>
          <div className="right">
            <Right />
          </div>
          <div className="wrap-small">
            <h3>Hızlı, Kolay, Güvenilir ve Profesyonel Hukuk Desteği</h3>
            <h2>Bilişim ve Teknoloji Avukatları</h2>
            <p>
              Teknoloji ve BT hukuku konusundaki deneyimimiz ve anlayışımızla
              gurur duyuyoruz ve her zaman olduğu gibi, tavsiye ve yasal temsil
              sağladığımız ana sektör olmaya devam ediyor.
            </p>
            <Link to="/iletisim" className="btn nobg">
              Bizimle İletişime Geçin
            </Link>
          </div>
        </div>
      </div>
      <div className="stripe white-nobg" />
      <div className="section footer">
        <div className="wrap-wide">
          <div className="footer-top">
            <div className="inside">
              <div className="logo">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <ul className="social-links">
                <li>
                  <a
                    className="s-icon"
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M22.459,5.5a8.763,8.763,0,0,1-2.471.678A4.337,4.337,0,0,0,21.88,3.794,8.907,8.907,0,0,1,19.144,4.83,4.3,4.3,0,0,0,11.7,7.768a4.446,4.446,0,0,0,.111.983A12.194,12.194,0,0,1,2.935,4.266a4.226,4.226,0,0,0-.582,2.166,4.307,4.307,0,0,0,1.914,3.584,4.292,4.292,0,0,1-1.949-.539V9.53A4.306,4.306,0,0,0,5.77,13.753a4.342,4.342,0,0,1-1.935.075,4.318,4.318,0,0,0,4.028,2.989,8.629,8.629,0,0,1-5.339,1.842A9.277,9.277,0,0,1,1.5,18.6a12.254,12.254,0,0,0,6.613,1.932A12.159,12.159,0,0,0,20.361,8.3c0-.183,0-.367-.013-.551A8.69,8.69,0,0,0,22.5,5.516Z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="s-icon"
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19.039,19.043H16.078V14.4c0-1.106-.023-2.53-1.544-2.53-1.544,0-1.78,1.2-1.78,2.449v4.722H9.792V9.5h2.845v1.3h.039a3.12,3.12,0,0,1,2.808-1.542c3,0,3.556,1.975,3.556,4.546v5.238ZM6.447,8.194A1.72,1.72,0,1,1,8.168,6.473,1.719,1.719,0,0,1,6.447,8.194ZM7.932,19.043H4.963V9.5H7.932ZM20.521,2H3.476A1.458,1.458,0,0,0,2,3.441V20.559A1.458,1.458,0,0,0,3.476,22H20.518A1.463,1.463,0,0,0,22,20.559V3.441A1.464,1.464,0,0,0,20.518,2Z" />
                    </svg>
                  </a>
                </li>
              </ul>
              <div className="disclaimer-footer">
                <div>
                  Bilişim - teknoloji varlıklarınız için tamamen meşru yasal
                  koruma elde etmenin çok stresli ve zaman alıcı bir iş
                  olabileceğini takdir ediyoruz. BT avukatlarımız ise bu sürecin
                  mümkün olduğunca basit ve acısız olmasını sağlayacaktır. BT
                  avukatlarımız, davanızı en profesyonel şekilde ele almak ve
                  size ve şirketinize önemli ölçüde fayda sağlayacak olumlu bir
                  sonuç sağlamak için son derece eğitimli ve deneyimlidir.
                </div>
              </div>
            </div>
            <div className="inside">
              <h3>Çalışma Alanlarımız</h3>
              <ul className="products-ul">
                <li>
                  <Link to="/calisma-alanlari/sosyal-medya-avukati">
                    Sosyal Medya Avukatı
                  </Link>
                </li>
                <li>
                  <Link to="/calisma-alanlari/fenomen-avukati">
                    Fenomen Avukatı
                  </Link>
                </li>
                <li>
                  <Link to="/calisma-alanlari/bilisim-avukati">
                    Bilişim Avukatı
                  </Link>
                </li>
                <li>
                  <a
                    href="https://lutfikurt.av.tr/izmir-agir-ceza-avukati"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    İzmir Ceza Avukatı
                  </a>
                </li>
              </ul>
            </div>
            <div className="inside">
              <h3>SİTE HARİTASI</h3>
              <ul>
                <li>
                  <Link className="level-1-a" to="/sikca-sorulan-sorular">
                    Sıkça Sorulan Sorular
                  </Link>
                </li>
                <li>
                  <Link className="level-1-a" to="/calisma-alanlari">
                    Çalışma Alanları
                  </Link>
                </li>
                <li>
                  <Link className="level-1-a" to="/iletisim">
                    Avukata Sor
                  </Link>
                </li>
                <li>
                  <Link className="level-1-a" to="/kurumsal">
                    Hakkımızda
                  </Link>
                </li>
                <li>
                  <Link className="level-1-a" to="/blog">
                    Blog
                  </Link>
                </li>
              </ul>
            </div>
            <div className="inside">
              <h3>Avukat Bilişim</h3>
              <p>
                BT/Teknoloji işletmenizin sağlam yasal korumaya ve desteğe
                ihtiyacı varsa, lütfen +90 (544) 588 34 42'den Avukat Bilişim
                Avukatları ile iletişime geçin.
              </p>
            </div>
          </div>
          <div className="stripe white" />
          <div className="footer-bottom">
            <span>© {y} Avukat Bilişim Hukuk Bürosu</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
