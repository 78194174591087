// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-calisma-alanlari-bilisim-avukati-js": () => import("./../../../src/pages/calisma-alanlari/bilisim-avukati.js" /* webpackChunkName: "component---src-pages-calisma-alanlari-bilisim-avukati-js" */),
  "component---src-pages-calisma-alanlari-fenomen-avukati-js": () => import("./../../../src/pages/calisma-alanlari/fenomen-avukati.js" /* webpackChunkName: "component---src-pages-calisma-alanlari-fenomen-avukati-js" */),
  "component---src-pages-calisma-alanlari-index-js": () => import("./../../../src/pages/calisma-alanlari/index.js" /* webpackChunkName: "component---src-pages-calisma-alanlari-index-js" */),
  "component---src-pages-calisma-alanlari-sosyal-medya-avukati-js": () => import("./../../../src/pages/calisma-alanlari/sosyal-medya-avukati.js" /* webpackChunkName: "component---src-pages-calisma-alanlari-sosyal-medya-avukati-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kurumsal-js": () => import("./../../../src/pages/kurumsal.js" /* webpackChunkName: "component---src-pages-kurumsal-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-sikca-sorulan-sorular-js": () => import("./../../../src/pages/sikca-sorulan-sorular.js" /* webpackChunkName: "component---src-pages-sikca-sorulan-sorular-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

