import React from "react"
import Google from "../../../static/assets/konum.svg"

const GoogleMap = () => (
  <div className="google-map">
    <a
      href="https://g.page/avcilarexclusive?share"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img
        title="Google Harita"
        src={Google}
        alt="Bilişim Avukatı, Sosyal Medya Avukatı, Fenomen Avukatı, İnternet Avukatı, Teknoloji Avukatı"
      />
    </a>
  </div>
)

export default GoogleMap
