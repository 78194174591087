import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { GoogleMap, Whatsapp } from "../../components/Utils/"
import GlobalStyle from "../../styles/Global"
import Theme from "../../styles/Theme"
import styled, { ThemeProvider } from "styled-components"

import Header from "./Header"

import Footer from "./Footer"

const SiteRoot = styled.div`
  background: #fff;
`

const Layout = ({ children, location }) => (
  <SiteRoot>
    <Helmet></Helmet>
    <GlobalStyle />

    <ThemeProvider theme={Theme}>
      <>
        <Header location={location} />
        {children}
        <Footer />
        <GoogleMap />
        <Whatsapp />
      </>
    </ThemeProvider>
  </SiteRoot>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
